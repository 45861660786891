



















import Vue from 'vue';

export default Vue.extend({
  components: {
    CreateForm: () => import(/* webpackChunkName: "create-v1" */ '@/components/Create.vue'),
    CreateFormV2: () => import(/* webpackChunkName: "create-v2" */ '@/components/CreateV2.vue'),
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
